/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Navbar } from "@blueprintjs/core";
import { FC } from "react";

export const MainNavbar: FC = () => {
  return (
    <Navbar>
      <Navbar.Group>
        <Navbar.Heading>読解基本語彙チェッカー</Navbar.Heading>
      </Navbar.Group>
    </Navbar>
  );
};
