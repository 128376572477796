/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { FC } from "react";

const nonHighlightedSpanStyle = (color: string) => css`
  color: ${color};
  background-color: inherit;
`;

const highlightedSpanStyle = (color: string) => css`
  color: #fff;
  background-color: ${color};
  margin: -2px;
  padding: 2px;
  border-radius: 3px;
`;

const morphemeSpanStyle = (color: string, isHighlighted: boolean) => {
  if (isHighlighted) {
    return highlightedSpanStyle(color);
  } else {
    return nonHighlightedSpanStyle(color);
  }
};

export interface MorphemeSpanProps {
  color: string;
  isHighlighted: boolean;
}

export const MorphemeSpan: FC<MorphemeSpanProps> = ({
  color,
  isHighlighted,
  children,
}) => {
  return <span css={morphemeSpanStyle(color, isHighlighted)}>{children}</span>;
};
MorphemeSpan.displayName = "MorphemeSpan";
