/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Summary, Category, CATEGORY_HEADINGS } from "~/models/category";
import { HTMLTable } from "@blueprintjs/core";
import { ColorMap } from "~models/color_map";
import { FC } from "react";

const SEMANTIC_WORDS: Category[] = [
  "proper_noun",
  "other",
  "rank12000",
  "rank7000",
  "rank4000",
  "rank2000",
  "rank1000",
  "numeral",
];

const ALL_WORDS: Category[] = [...SEMANTIC_WORDS, "part_aux"];

const summaryTableStyle = css`
  th {
    text-align: center !important;
  }
  td {
    text-align: right !important;
  }
`;

export interface SummaryTableProps {
  summary: Summary;
  colorMap: ColorMap;
}

export const SummaryTable: FC<SummaryTableProps> = ({ summary, colorMap }) => {
  const semanticWordCounts = SEMANTIC_WORDS.map((category) =>
    [...summary[category].values()]
      .map(({ count }) => count)
      .reduce((a, b) => a + b, 0),
  );
  const semanticWordTotal = semanticWordCounts.reduce((a, b) => a + b, 0);
  const allWordCounts = ALL_WORDS.map((category) =>
    [...summary[category].values()]
      .map(({ count }) => count)
      .reduce((a, b) => a + b, 0),
  );
  const allWordTotal = allWordCounts.reduce((a, b) => a + b, 0);
  return (
    <HTMLTable css={summaryTableStyle}>
      <thead>
        <tr>
          <th>のべ語数</th>
          {ALL_WORDS.map((category) => (
            <th
              key={category}
              style={{
                color: colorMap[category],
              }}
            >
              {CATEGORY_HEADINGS.get(category)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>総意味語数</th>
          {semanticWordCounts.map((count, i) => (
            <td key={i}>{Math.floor((count / semanticWordTotal) * 100)}%</td>
          ))}
          <td />
        </tr>
        <tr>
          <td>{semanticWordTotal}</td>
          {semanticWordCounts.map((count, i) => (
            <td key={i}>{count}</td>
          ))}
          <td />
        </tr>
        <tr>
          <th>総語数</th>
          {allWordCounts.map((count, i) => (
            <td key={i}>{Math.floor((count / allWordTotal) * 100)}%</td>
          ))}
        </tr>
        <tr>
          <td>{allWordTotal}</td>
          {allWordCounts.map((count, i) => (
            <td key={i}>{count}</td>
          ))}
        </tr>
      </tbody>
    </HTMLTable>
  );
};
