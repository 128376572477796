import React, { useEffect } from "react";
import { FC, useState } from "react";

const BUCKET_ENDPOINT = "https://minio2.overworks.jp/yukari-basic/";

export const MaterialDownloads: FC = () => {
  const [files, setFiles] = useState<string[] | null>(null);
  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      const resp = await fetch(
        `${BUCKET_ENDPOINT}?list-type=2&prefix=materials/&delimiter=/`,
        {
          signal: abortController.signal,
        },
      );
      const xml = await resp.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(xml, "application/xml");
      const keys = [...doc.querySelectorAll("Contents > Key")].map(
        (el) => el.textContent!,
      );
      setFiles(keys);
    })();

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <>
      <ul>
        {files &&
          files.map((key) => {
            return (
              <li key={key}>
                <a
                  href={`${BUCKET_ENDPOINT}${key}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {key.split("/").pop()}
                </a>
              </li>
            );
          })}
      </ul>
    </>
  );
};
