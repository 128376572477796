import React, { FC, useMemo } from "react";
import { Tree, ITreeNode } from "@blueprintjs/core";
import { Category, Summary, CATEGORY_HEADINGS } from "~/models/category";
import { ColorMap } from "~models/color_map";

interface CategoryLabelProps {
  colorMap: ColorMap;
  category: Category;
}
const CategoryLabel: FC<CategoryLabelProps> = ({
  colorMap,
  category,
  children,
}) => {
  const color = colorMap[category];
  return <span style={{ color }}>{children}</span>;
};

function buildStatelessTree(summary: Summary, colorMap: ColorMap): ITreeNode[] {
  return [...CATEGORY_HEADINGS].map(([category, labelText]) => {
    const descByQueryMap = summary[category];
    let total = 0;
    const childNodes = [...descByQueryMap]
      .sort(([_q1, d1], [_q2, d2]) => {
        return d2.count - d1.count;
      })
      .map(([query, desc]) => {
        total += desc.count;
        return {
          id: queryToQueryId(query),
          isSelected: false,
          label: desc.label,
          secondaryLabel: `${desc.count}`,
        };
      });
    return {
      id: categoryToCategoryId(category),
      isExpanded: true,
      isSelected: false,
      label: (
        <CategoryLabel category={category} colorMap={colorMap}>
          {labelText}
        </CategoryLabel>
      ),
      childNodes,
      secondaryLabel: `${total}(${childNodes.length})`,
    };
  });
}

function categoryIdToCategory(categoryId: string): Category {
  return categoryId.replace(/^category-/, "") as Category;
}
function categoryToCategoryId(category: string): string {
  return `category-${category}`;
}

function queryIdToQuery(queryId: string): string {
  return queryId.replace(/^query-/, "");
}
function queryToQueryId(query: string): string {
  return `query-${query}`;
}

function applyStateWithTree(
  categoryNodes: ITreeNode[],
  expandedCategorySet: Set<string>,
  selectedQuerySet: Set<string>,
): ITreeNode[] {
  return categoryNodes.map((categoryNode) => {
    const category = categoryIdToCategory(categoryNode.id as string);
    const isExpanded = expandedCategorySet.has(category);
    let isCategorySelected = true;
    const childNodes = categoryNode.childNodes!.map((queryNode) => {
      const query = queryIdToQuery(queryNode.id as string);
      const isSelected = selectedQuerySet.has(query);
      if (!isSelected) {
        isCategorySelected = false;
      }
      return Object.assign({}, queryNode, { isSelected });
    });
    if (childNodes.length === 0) {
      isCategorySelected = false;
    }
    return Object.assign({}, categoryNode, {
      childNodes,
      isExpanded,
      isSelected: isCategorySelected,
    });
  });
}

export interface SummaryTreeProps {
  summary: Summary;
  colorMap: ColorMap;
  selectedQuerySet: Set<string>;
  expandedCategorySet: Set<Category>;
  onSelect: (queries: Set<string>) => void;
  onExpansionChange: (categories: Set<Category>) => void;
}
export const SummaryTree: FC<SummaryTreeProps> = ({
  summary,
  colorMap,
  selectedQuerySet,
  expandedCategorySet,
  onSelect,
  onExpansionChange,
}) => {
  const statelessTree = useMemo(() => {
    return buildStatelessTree(summary, colorMap);
  }, [summary, colorMap]);
  const statefulTree = applyStateWithTree(
    statelessTree,
    expandedCategorySet,
    selectedQuerySet,
  );
  const handleNodeCollapse = (node: ITreeNode) => {
    const category = categoryIdToCategory(node.id as string);
    const newSet = new Set([...expandedCategorySet]);
    newSet.delete(category);
    onExpansionChange(newSet);
  };
  const handleNodeExpand = (node: ITreeNode) => {
    const category = categoryIdToCategory(node.id as string);
    onExpansionChange(new Set([...expandedCategorySet, category]));
  };
  const handleCategoryNodeClick = (node: ITreeNode) => {
    if (node.childNodes!.length === 0) {
      return;
    }
    if (node.isSelected) {
      onSelect(new Set());
    } else {
      const queries = node.childNodes!.map((queryNode) =>
        queryIdToQuery(queryNode.id as string),
      );
      onSelect(new Set(queries));
    }
  };
  const handleQueryNodeClick = (node: ITreeNode) => {
    const query = queryIdToQuery(node.id as string);
    if (selectedQuerySet.size === 1 && selectedQuerySet.has(query)) {
      onSelect(new Set());
    } else {
      onSelect(new Set([queryIdToQuery(node.id as string)]));
    }
  };
  const handleNodeClick = (node: ITreeNode) => {
    if ((node.id as string).startsWith("category-")) {
      handleCategoryNodeClick(node);
    } else {
      handleQueryNodeClick(node);
    }
  };
  return (
    <Tree
      contents={statefulTree}
      onNodeCollapse={handleNodeCollapse}
      onNodeExpand={handleNodeExpand}
      onNodeClick={handleNodeClick}
    />
  );
};
