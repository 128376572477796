/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { FC } from "react";

const floatingStyle = css`
  position: sticky;
  padding-top: 20px;
  top: 0;
  left: 0;
  margin: 0 6px;
`;

export const Floating: FC = ({ children }) => {
  return <div css={floatingStyle}>{children}</div>;
};
