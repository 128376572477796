/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { FC } from "react";

export const verticalStackStyle = css`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
`;

export const fixedSizeStyle = css`
  position: relative;
  flex: 1 0 auto;
`;

export const VerticalStack: FC = ({ children }) => (
  <div css={verticalStackStyle}>{children}</div>
);
