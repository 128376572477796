import { Summary, CATEGORY_HEADINGS } from "~models/category";
import { utf16leEncode } from "~utils";

export const downloadSummaryCsv = (summary: Summary) => {
  const rows = [...CATEGORY_HEADINGS]
    .map(([id, label]) => {
      const total = [...summary[id]]
        .map(([_, { count }]) => count)
        .reduce((a, b) => a + b, 0);
      const distinctCount = summary[id].size;
      return [...summary[id]].map(([_query, { label: word, count }], idx) => {
        return [
          idx === 0 ? label : "",
          idx === 0 ? `${total}(${distinctCount})` : "",
          word,
          count,
        ];
      });
    })
    .reduce((a, b) => [...a, ...b], []);
  const utf8 = rows.map((cols) => cols.join("\t")).join("\r\n") + "\n";
  const utf16le = utf16leEncode(utf8);
  const blob = new Blob([utf16le.buffer], { type: "text/csv" });
  const objectUrl = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.download = "yukari-chuta-jp.csv";
  a.href = objectUrl;
  a.click();
};
