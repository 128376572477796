interface RawMorpheme {
  format: string;
  key: string;
  orthBase: string;
  surface: string;
  lForm?: string;
  lemma?: string;
  pos4: string;
  pos3: string;
  pos2: string;
  pos1: string;
}

export interface IMorpheme extends RawMorpheme {
  key: string;
  query: string;
}

function normalizeForeignWord(lemma: string) {
  return lemma.replace(/-.*$/, "");
}
function morphToKey(m: RawMorpheme) {
  return (m.lemma && normalizeForeignWord(m.lemma)) ?? m.surface;
}
function morphToQueryWithKey(key: string) {
  return `${key}`;
}

export interface RankMap {
  [surface: string]: string | null;
}

export type Rank = 1000 | 2000 | 4000 | 7000 | 12000;

const DEFAULT_BASE_URL =
  process.env.API_ENDPOINT || process.env.NODE_ENV === "production"
    ? "https://yukari2-api.overworks.jp/"
    : "http://10.100.1.21:8124/";

export default class Client {
  constructor(private base: string = DEFAULT_BASE_URL) {}

  async mecab(text: string, signal?: AbortSignal): Promise<IMorpheme[]> {
    const res: RawMorpheme[] = await fetch(
      new URL("/mecab", this.base).toString(),
      {
        method: "post",
        body: JSON.stringify({ text }),
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        signal,
      },
    ).then((res) => res.json());
    return res.map((m) => {
      const key = morphToKey(m);
      const query = morphToQueryWithKey(key);
      return { ...m, key, query };
    });
  }

  async yukari(
    queries: string[],
    signal?: AbortSignal,
  ): Promise<Map<string, Rank | null>> {
    const res: RankMap = await fetch(new URL("/yukari", this.base).toString(), {
      method: "post",
      body: JSON.stringify(queries),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      signal,
    }).then((res) => res.json());
    return new Map(
      Object.entries(res).map(
        ([k, v]) => [k, v && ~~v] as [string, Rank | null],
      ),
    );
  }
}
