import { IMorpheme, Rank } from "../api";

export type Category =
  | "rank1000"
  | "rank2000"
  | "rank4000"
  | "rank7000"
  | "rank12000"
  | "numeral"
  | "proper_noun"
  | "part_aux"
  | "symbol"
  | "other";
export interface CategorizedMorpheme extends IMorpheme {
  category: Category;
}

const ASCII_SYMBOLS = new Set("!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~");

export class RankBySurface {
  constructor(private rankBySurfaceMap: Map<string, Rank | null>) {}

  getCategory(m: IMorpheme): Category {
    if (ASCII_SYMBOLS.has(m.surface)) {
      return "symbol";
    }
    switch (m.pos1) {
      case "名詞":
        switch (m.pos2) {
          case "数詞":
            return "numeral";
          case "固有名詞":
            return "proper_noun";
        }
        break;
      case "助詞":
      case "助動詞":
        return "part_aux";
      case "補助記号":
      case "空白":
        return "symbol";
    }
    const rank = this.rankBySurfaceMap.get(m.query);
    switch (rank) {
      case 1000:
        return "rank1000";
      case 2000:
        return "rank2000";
      case 4000:
        return "rank4000";
      case 7000:
        return "rank7000";
      case 12000:
        return "rank12000";
    }
    return "other";
  }

  categorize(m: IMorpheme): CategorizedMorpheme {
    const category = this.getCategory(m);
    return { ...m, category };
  }
}

export type Summary = {
  // key of map is a query
  [C in Category]: Map<
    string,
    {
      label: string;
      count: number;
    }
  >;
};

export function summarize(ms: CategorizedMorpheme[]): Summary {
  const summary: Summary = {
    rank1000: new Map(),
    rank2000: new Map(),
    rank4000: new Map(),
    rank7000: new Map(),
    rank12000: new Map(),
    numeral: new Map(),
    proper_noun: new Map(),
    part_aux: new Map(),
    symbol: new Map(),
    other: new Map(),
  };
  for (const m of ms) {
    const map = summary[m.category];
    if (!map.has(m.query)) {
      const label = m.orthBase || m.surface;
      map.set(m.query, { label, count: 0 });
    }
    const { label, count } = map.get(m.query)!;
    map.set(m.query, { label, count: count + 1 });
  }
  return summary;
}

export const CATEGORY_HEADINGS: Map<Category, string> = new Map<
  Category,
  string
>([
  ["proper_noun", "固有名詞"],
  ["other", "級外"],
  ["rank12000", "12000語"],
  ["rank7000", "7000語"],
  ["rank4000", "4000語"],
  ["rank2000", "2000語"],
  ["rank1000", "1000語"],
  ["numeral", "数詞"],
  ["part_aux", "助(動)詞"],
  ["symbol", "記号"],
]);
