import React from "react";
import { Checker } from "./Checker";
import { GlobalStyle } from "~components/GlobalStyle";

export const App = () => (
  <React.Fragment>
    <GlobalStyle />
    <Checker />
  </React.Fragment>
);
