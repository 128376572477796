/** @jsx jsx */
import { css, jsx, Global } from "@emotion/core";
import { Colors } from "@blueprintjs/core";

export const globalStyle = css`
  body {
    background-color: ${Colors.LIGHT_GRAY5};
    font-size: 14px;
  }
`;

export const GlobalStyle = () => <Global styles={globalStyle} />;
