export function utf16leEncode(str: string) {
  const buf = new Uint8Array(str.length * 2 + 2);
  buf[0] = 0xff;
  buf[1] = 0xfe;
  for (let i = 0; i < str.length; i++) {
    const c = str.charCodeAt(i);
    buf[i * 2 + 2] = c & 0xff;
    buf[i * 2 + 3] = (c >>> 8) & 0xff;
  }
  return buf;
}
