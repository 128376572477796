import React from "react";
import { FC } from "react";
import { MorphemeSpan } from "./MorphemeSpan";

export interface ColoredMorpheme {
  color: string;
  surface: string;
  isHighlighted: boolean;
}

export interface ColoredTextProps {
  morphemes: ColoredMorpheme[];
}

export const ColoredText: FC<ColoredTextProps> = ({ morphemes }) => {
  return (
    <>
      {morphemes.map(({ color, surface, isHighlighted }, index) => (
        <MorphemeSpan
          key={`${index}/${surface}/${color}/${isHighlighted}`}
          color={color}
          isHighlighted={isHighlighted}
        >
          {surface}
        </MorphemeSpan>
      ))}
    </>
  );
};
