import { CategorizedMorpheme, RankBySurface } from "~models/category";
import Client from "~api";

export type State =
  | { type: "initial" }
  | { type: "inflight"; abortController: AbortController }
  | { type: "completed"; morphemes: CategorizedMorpheme[] };

export async function request(text: string, { signal }: AbortController) {
  const client = new Client();
  const originalMorphemes = await client.mecab(text, signal);
  const queries = [...new Set(originalMorphemes.map(({ query }) => query))];
  const rankBySurfaceMap = await client.yukari(queries, signal);
  const rankBySurface = new RankBySurface(rankBySurfaceMap);
  const morphemes = originalMorphemes.map((m) => rankBySurface.categorize(m));
  return morphemes;
}
