import { Colors } from "@blueprintjs/core";

/*
固有名詞：ピンク
級外：赤
12000：オレンジ
7000：紫
4000：青
2000：薄い緑
1000：濃い緑
数字：灰色
助（動）詞：黒
記号：黒
*/

export const DefaultColorMap = {
  proper_noun: Colors.ROSE5,
  other: Colors.RED3,
  rank12000: Colors.ORANGE3,
  rank7000: Colors.VIOLET3,
  rank4000: Colors.COBALT2,
  rank2000: Colors.LIME2,
  rank1000: Colors.FOREST1,
  numeral: Colors.GRAY5,
  part_aux: Colors.BLACK,
  symbol: Colors.BLACK,
};

export type ColorMap = typeof DefaultColorMap;
