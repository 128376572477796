import React, { useState, FC, useEffect } from "react";
import { VerticalStack } from "~components/VerticalStack";
import { TextPane } from "~components/TextPane";
import { MainSideSplit } from "~components/MainSideSplit";
import { EditorCard } from "~components/EditorCard";
import { ColoredText } from "~components/ColoredText";
import { HighlightableEditor } from "~components/HighlightableEditor";
import { request, State } from "~hooks/yukari_api";
import { DefaultColorMap, ColorMap } from "~models/color_map";
import {
  Intent,
  Button,
  Card,
  Elevation,
  NonIdealState,
  Navbar,
  ButtonGroup,
  Divider,
  H4,
  Icon,
  AnchorButton,
} from "@blueprintjs/core";
import { Floating } from "~components/Floating";
import { IconNames } from "@blueprintjs/icons";
import {
  CategorizedMorpheme,
  summarize,
  CATEGORY_HEADINGS,
  Category,
} from "~models/category";
import { SummaryTable } from "~components/SummaryTable";
import { EditorLayout } from "~components/EditorLayout";
import { SummaryTree } from "./SummaryTree";
import { ColorPalette } from "~components/ColorPalette";
import { Scrollable } from "~components/Scrollable";
import { SideNavbar } from "~components/SideNavbar";
import { downloadSummaryCsv } from "~models/summary_csv";
import { MaterialDownloads } from "~components/MaterialDownloads";

const COLOR_MAP_LS_KEY = "colorMap-v3";

const initilalColorMap = () => {
  const colorMapJson = localStorage.getItem(COLOR_MAP_LS_KEY);
  if (colorMapJson === null) {
    return DefaultColorMap;
  }
  try {
    const loaded = JSON.parse(colorMapJson);
    return Object.assign({}, DefaultColorMap, loaded);
  } catch (e) {
    localStorage.removeItem(COLOR_MAP_LS_KEY);
    return DefaultColorMap;
  }
};

const saveColorMap = (colorMap: ColorMap) => {
  localStorage.setItem(COLOR_MAP_LS_KEY, JSON.stringify(colorMap));
};

export const Checker = () => {
  const [text, setText] = useState("");
  const [isBold] = useState(true);
  const [colorMap, setColorMap] = useState(initilalColorMap());
  const [status, setStatus] = useState("initial" as State["type"]);
  const [abortController, setAbortController] = useState(new AbortController());
  const [morphemes, setMorphemes] = useState([] as CategorizedMorpheme[]);
  const [selectedQuerySet, setSelectedQuerySet] = useState(new Set<string>());
  const [expandedCategorySet, setExpandedCategorySet] = useState(
    new Set(CATEGORY_HEADINGS.keys()),
  );

  useEffect(() => {
    saveColorMap(colorMap);
  }, [colorMap]);

  const submit = async () => {
    const abortController = new AbortController();
    setAbortController(abortController);
    setStatus("inflight");
    try {
      const morphemes = await request(text, abortController);
      setMorphemes(morphemes);
      setStatus("completed");
    } catch {
      setStatus("initial");
    }
  };

  const abort = () => {
    abortController.abort();
  };

  const reset = () => {
    setMorphemes([]);
    setAbortController(new AbortController());
    setStatus("initial");
  };

  const handleSelect = (querySet: Set<string>) => {
    setSelectedQuerySet(querySet);
  };

  const handleExpansionChange = (categories: Set<Category>) => {
    setExpandedCategorySet(categories);
  };

  const expandAllCategories = () => {
    setExpandedCategorySet(new Set(CATEGORY_HEADINGS.keys()));
  };

  const collapseAllCategories = () => {
    setExpandedCategorySet(new Set());
  };

  const handleColorMapChange = (colorMap: ColorMap) => {
    setColorMap(colorMap);
  };

  const handleColorMapReset = () => {
    const ok = window.confirm("色設定を初期設定に戻しますか?");
    if (ok) {
      handleColorMapChange(DefaultColorMap);
    }
  };

  const coloredMorphemes = morphemes.map(({ query, format, category }) => {
    return {
      color: colorMap[category],
      surface: format,
      isHighlighted: selectedQuerySet.has(query),
    };
  });

  const summary = summarize(morphemes);

  const handleDownloadCsv = () => {
    downloadSummaryCsv(summary);
  };

  return (
    <VerticalStack>
      <MainSideSplit>
        <TextPane>
          <EditorLayout>
            <EditorCard width={640}>
              <HighlightableEditor
                isColored={status === "completed"}
                isInEdit={status === "initial"}
                isBold={isBold}
                value={text}
                onChange={(e) => setText(e.currentTarget.value)}
              >
                {coloredMorphemes && (
                  <ColoredText morphemes={coloredMorphemes} />
                )}
              </HighlightableEditor>
            </EditorCard>
            <ControlStrip>
              <ExecuteButton
                status={status}
                submit={submit}
                abort={abort}
                reset={reset}
              />
              <Divider />
              <DiscardButton reset={reset} text={text} onChange={setText} />
            </ControlStrip>
          </EditorLayout>
          {status === "completed" && (
            <Card elevation={Elevation.ZERO}>
              <SummaryTable colorMap={colorMap} summary={summary} />
            </Card>
          )}
          {status === "completed" && (
            <Card elevation={Elevation.ZERO} style={{ marginTop: "12px" }}>
              <ColorPalette
                colorMap={colorMap}
                onChange={handleColorMapChange}
              />
              <Button onClick={handleColorMapReset} intent={Intent.WARNING}>
                初期設定に戻す
              </Button>
            </Card>
          )}
          <p style={{ margin: "24px auto", width: 692 }}>
            読解基本語彙チェッカーは、単語の汎用性をチェックするツールです。判定は「汎用性を重視した日本語教育読解語彙リストVer.1.0」に基づいており、このリストはコーパス（BCCWJおよび日本語教科書）と統計データを使って作成されています。
          </p>
          <p style={{ margin: "24px auto", width: 692 }}>
            単語の汎用性レベルは6段階で色別表示されます（一部の品詞には汎用性レベルが付きません）。このリストの語彙は、上位7,000語が基本語彙、7,001～12,000語がそれに準じる語彙、12,001語以降は専門語彙です。
          </p>
          <div style={{ margin: "24px auto", width: 692 }}>
            <H4>使い方</H4>
            <ul>
              <li>テキストボックスに文や単語を入力してボタンを押す。</li>
              <li>
                結果が以下のように表示される。
                <ul>
                  <li>単語が色別表示（表示色変更可）</li>
                  <li>各レベルに含まれる単語の割合</li>
                  <li>
                    右側は、レベル別の単語リスト（CSVダウンロード可、クリックでハイライト）
                  </li>
                </ul>
              </li>
              <li>再入力するには、再入力ボタンを押す。</li>
            </ul>
          </div>
          <div style={{ margin: "24px auto", width: 692 }}>
            <H4>資料ダウンロード</H4>
            <MaterialDownloads />
          </div>
        </TextPane>
        <VerticalStack>
          <SideNavbar>
            <Button
              large
              icon={IconNames.TH}
              disabled={status !== "completed"}
              onClick={handleDownloadCsv}
            />
            <Navbar.Divider />
            <ButtonGroup>
              <Button
                large
                minimal
                icon={IconNames.EXPAND_ALL}
                disabled={status !== "completed"}
                onClick={expandAllCategories}
              />
              <Button
                large
                minimal
                icon={IconNames.COLLAPSE_ALL}
                disabled={status !== "completed"}
                onClick={collapseAllCategories}
              />
            </ButtonGroup>
          </SideNavbar>
          <Scrollable>
            {status === "completed" ? (
              <SummaryTree
                colorMap={colorMap}
                summary={summary}
                selectedQuerySet={selectedQuerySet}
                expandedCategorySet={expandedCategorySet}
                onSelect={handleSelect}
                onExpansionChange={handleExpansionChange}
              />
            ) : (
              <NonIdealState icon={IconNames.MANUAL} />
            )}
          </Scrollable>
        </VerticalStack>
      </MainSideSplit>
    </VerticalStack>
  );
};

const ControlStrip: FC = ({ children }) => {
  return (
    <Floating>
      <VerticalStack>{children}</VerticalStack>
    </Floating>
  );
};

interface ExecuteButtonProps {
  status: State["type"];
  submit: () => void;
  abort: () => void;
  reset: () => void;
}
const ExecuteButton: FC<ExecuteButtonProps> = ({
  status,
  submit,
  abort,
  reset,
}) => {
  if (status === "completed") {
    return (
      <Button
        large
        intent={Intent.SUCCESS}
        icon={IconNames.EDIT}
        onClick={() => {
          reset();
        }}
      />
    );
  } else {
    return (
      <Button
        large
        intent={Intent.PRIMARY}
        icon={IconNames.DIRECTION_RIGHT}
        onClick={() => {
          if (status === "inflight") {
            abort();
          } else {
            submit();
          }
        }}
        loading={status === "inflight"}
      />
    );
  }
};

interface DiscardButtonProps {
  reset: () => void;
  text: string;
  onChange: (text: string) => void;
}
const DiscardButton: FC<DiscardButtonProps> = ({ reset, text, onChange }) => {
  const [backup, setBackup] = useState(null as string | null);
  useEffect(() => {
    if (text.length !== 0) {
      setBackup(null);
    }
  }, [text]);
  const discard = () => {
    reset();
    setBackup(text);
    onChange("");
  };
  const restore = () => {
    if (backup !== null) {
      onChange(backup);
      setBackup(null);
    }
  };
  if (backup === null) {
    return (
      <Button
        large
        minimal
        icon={IconNames.TRASH}
        intent={Intent.DANGER}
        onClick={discard}
        disabled={text.length === 0}
      />
    );
  } else {
    return <Button large minimal icon={IconNames.UNDO} onClick={restore} />;
  }
};
