/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { FC } from "react";

export const editorLayoutStyle = css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: start;
  margin-bottom: 20px;
`;

export const EditorLayout: FC = ({ children }) => {
  return <div css={editorLayoutStyle}>{children}</div>;
};
