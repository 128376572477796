/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import TextareaAutosize from "react-autosize-textarea";
import { useRef, FC } from "react";

const hwtContainerStyle = (isBold: boolean) => css`
  display: inline-block;
  position: relative;
  overflow: hidden !important;
  -webkit-text-size-adjust: none !important;
  width: 100%;
  font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo,
    メイリオ, Osaka, "MS PGothic", arial, helvetica, sans-serif;
  font-size: 12pt;
  line-height: 1.5em;
  font-weight: ${isBold ? "bold" : "inherit"};
`;

const hwtBackdropStyle = (isColored: boolean, isInEdit: boolean) => {
  return css`
    position: absolute !important;
    top: 0 !important;
    right: -99px !important;
    bottom: 0 !important;
    left: 0 !important;
    padding-right: 99px !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    opacity: ${isColored ? 1 : 0};
    z-index: ${isInEdit ? 5 : 10};
  `;
};

const hwtContent = css`
  background: none transparent !important;
  width: 100%;
  font-kerning: none;
`;

const hwtHighlightsStyle = css`
  ${hwtContent}
  width: auto !important;
  height: auto !important;
  border-color: transparent !important;
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  overflow: hidden !important;
`;

const hwtInputStyle = (isColored: boolean, isInEdit: boolean) => css`
  ${hwtContent}
  display: block !important;
  position: relative !important;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  border-radius: 0;
  font: inherit;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  resize: none;
  //color: transparent;
  caret-color: #000;
  opacity: ${isColored ? 0 : 1};
  z-index: ${isInEdit ? 10 : 5};
`;

interface HighlightableEditorProps {
  value?: React.HTMLProps<HTMLTextAreaElement>["value"];
  onChange?: React.HTMLProps<HTMLTextAreaElement>["onChange"];
  placeholder?: React.HTMLProps<HTMLTextAreaElement>["placeholder"];
  isColored: boolean;
  isInEdit: boolean;
  isBold: boolean;
}

export const HighlightableEditor: FC<HighlightableEditorProps> = ({
  value,
  onChange,
  children,
  isColored,
  isInEdit,
  isBold,
}) => {
  const backdropRef = useRef(null as null | HTMLDivElement);

  const handleScroll = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const scrollTop = (event.target as any).scrollTop;
    const backdropElem = backdropRef.current;
    if (backdropElem !== null) {
      backdropElem.scrollTop = scrollTop;
    }
  };

  return (
    <div css={hwtContainerStyle(isBold)}>
      <div ref={backdropRef} css={hwtBackdropStyle(isColored, isInEdit)}>
        <div css={hwtHighlightsStyle}>{children}</div>
      </div>
      <TextareaAutosize
        css={hwtInputStyle(isColored, isInEdit)}
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
        value={value}
        onChange={onChange}
        onScroll={handleScroll}
        rows={5}
      />
    </div>
  );
};
