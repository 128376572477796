/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { VerticalStack } from "./VerticalStack";
import { FC } from "react";
import { MainNavbar } from "./MainNavbar";
import { Scrollable } from "./Scrollable";

const textPaneInnerStyle = css`
  margin: 0 12px;
`;

export const TextPaneInner: FC = ({ children }) => {
  return <div css={textPaneInnerStyle}>{children}</div>;
};

export const TextPane: FC = ({ children }) => {
  return (
    <VerticalStack>
      <MainNavbar />
      <Scrollable>
        <TextPaneInner>{children}</TextPaneInner>
      </Scrollable>
    </VerticalStack>
  );
};
