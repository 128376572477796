/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { FC } from "react";
import { Card, Elevation } from "@blueprintjs/core";

export const editorCardStyle = (width: number) => css`
  margin-top: 20px;
  padding: 12px !important;
  width: ${width}px;
`;

export interface EditorCardProp {
  width: number;
}

export const EditorCard: FC<EditorCardProp> = ({ children, width }) => {
  return (
    <Card elevation={Elevation.TWO} css={editorCardStyle(width)}>
      {children}
    </Card>
  );
};
