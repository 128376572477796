/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { FC } from "react";

export const scrollableStyle = css`
  position: relative;
  height: 100%;
  overflow: auto;
`;

export const Scrollable: FC = ({ children }) => (
  <div css={scrollableStyle}>{children}</div>
);
