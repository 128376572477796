/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Navbar } from "@blueprintjs/core";
import { FC } from "react";

export const SideNavbar: FC = ({ children }) => {
  return (
    <Navbar>
      <Navbar.Group>{children}</Navbar.Group>
    </Navbar>
  );
};
